export type ClassNameProp =
  | string
  | Record<string, boolean | undefined>
  | Array<string | Record<string, boolean | undefined>>;

export enum Placeholder {
  Empty = '-',
}

export enum Separator {
  Colon = ':',
  SemiColon = ';',
  Underscore = '_',
  Dash = '-',
  Dot = '.',
}

export enum Layout {
  Default = 'LayoutDefault',
  Empty = 'LayoutEmpty',
}

export enum FileFormat {
  CSV = '.csv',
  JPEG = '.jpeg',
  JPG = '.jpg',
  PNG = '.png',
}

export enum FileMimeType {
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

export interface LabelValueItem {
  label: string;
  value: string | number | boolean;
  className?: string;
}

export enum AriaState {
  True = 'true',
  False = 'false',
}
