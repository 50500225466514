export default {
  funding: {
    emptyMessageText: 'No funding instructions yet',
    note: 'Documents in this section are stored for 14 days from the date of creation and are automatically deleted afterward.\n If you wish to keep a document, please download it to your device.',
  },
  vatInvoices: {
    emptyMessageText: 'No invoices yet',
  },
  merchantReports: {
    emptyMessageText: 'No reports yet',
  },
  documentsReports: {
    emptyMessageText: 'No reports yet',
  },
};
