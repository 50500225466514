import type { InjectionKey } from 'vue';
import { provide, ref } from 'vue';

export type IsLoading = (
  entity: LoadingAction,
  id?: string | number,
) => boolean;

export const loadingKey = Symbol() as InjectionKey<IsLoading>;

export enum LoadingAction {
  Download = 'download',
  SubmitCode = 'submit-code',
  TopupCrypto = 'topup-crypto',
  WithdrawCrypto = 'withdraw-crypto',
  WithdrawFiat = 'withdraw-fiat',
  ChangePassword = 'change-password',
  FetchCsv = 'fetch-csv',
  CreateApi = 'create-api',
  UpdateApi = 'update-api',
  FetchMerchant = 'fetch-merchant',
  GenerateKey = 'generate-key',
  ResendWebhook = 'resend-webhook',
  FetchInvoices = 'fetch-invoices',
  CancelIntentPayment = 'cancel-intent-payment',
  ConfirmIntentPayment = 'confirm-intent-payment',
  EnableDonate = 'enable-donate',
  EnablePaylinks = 'enable-paylinks',
  UpdateDonatePresets = 'update-donate-presets',
  CreatePayouts = 'create-payouts',
  FetchPayouts = 'fetch-payouts',
  CreatePayoutOrders = 'create-payout-orders',
  ConfirmPayoutPayment = 'confirm-payout-payment',
  ChangeDefaultCurrency = 'change-default-currency',
  FetchBankAccounts = 'fetch-bank-accounts',
  AddBankAccount = 'add-bank-account',
  UpdateOrgAccount = 'update-org-account',
  CreateExchange = 'create-exchange',
  ConfirmExchange = 'confirm-exchange',
  SendFeedback = 'send-feedback',
  CompleteOnboarding = 'complete-onboarding',
  FetchPayersData = 'fetch-payers-data',
  UpdatePayers = 'update-payers',
  UpdateCryptoSettings = 'update-crypto-settings',
  FetchCryptoSettings = 'fetch-crypto-settings',
  TableDataLoading = 'table-data-loading',
  CreateFunding = 'create-funding',
  FetchBalanceStatement = 'fetch-balance-statement',
  FetchTransactions = 'fetch-transactions',
  UpdateMerchant = 'update-merchant',
  UpdatePayouts = 'update-payouts',
  CreateSignature = 'create-signature',
  VerifyDevice = 'verify-device',
}

export interface EntityLoadingComposable {
  isLoading: IsLoading;
  toggleLoading: (entity: LoadingAction, id?: string | number) => void;
  LoadingAction: typeof LoadingAction;
}

const globalLoadingEntities = ref<LoadingAction[]>([]);

export default function (isGlobal?: boolean): EntityLoadingComposable {
  const loadingEntities = isGlobal
    ? globalLoadingEntities
    : ref<LoadingAction[]>([]);

  provide(loadingKey, isLoading);

  function isLoading(entity: LoadingAction, id?: string | number): boolean {
    const entityName = id ? ((entity + `${id}`) as LoadingAction) : entity;

    return loadingEntities.value.includes(entityName);
  }

  function toggleLoading(entity: LoadingAction, id?: string | number) {
    const entityName = id ? ((entity + `${id}`) as LoadingAction) : entity;

    const entityIndex = loadingEntities.value.findIndex(
      (el) => el === entityName,
    );

    if (entityIndex > -1) {
      loadingEntities.value.splice(entityIndex, 1);
    } else {
      loadingEntities.value.push(entityName);
    }
  }

  return { isLoading, toggleLoading, LoadingAction };
}
