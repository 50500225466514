import { HttpStatus, InternalStatus } from '@/app/types/api/config/config';
import { RouteName } from '@/app/types/app/routes';

import ClientResponseError from '@/app/errors/ClientResponseError';
import SimpleClientError from '@/app/errors/SimpleClientError';

import router from '@/router';

export function resolveRoute(name: RouteName) {
  return router.resolve({ name });
}

export function resolveServicePageRoute(
  error: Error | ClientResponseError | SimpleClientError,
  toRouteName: RouteName,
) {
  if (error instanceof ClientResponseError) {
    switch (error.status) {
      // Maintenance
      case HttpStatus.ServiceUnavailable:
        if (toRouteName === RouteName.Maintenance) {
          return;
        }

        return resolveRoute(RouteName.Maintenance);
      // Forbidden
      case HttpStatus.Forbidden:
        if (toRouteName === RouteName.AccessRestricted) {
          return;
        }

        return resolveRoute(RouteName.AccessRestricted);
      // No signature
      case InternalStatus.NoSignature:
        if (toRouteName === RouteName.Signature) {
          return;
        }

        return resolveRoute(RouteName.Signature);

      // Device Verification
      case InternalStatus.DeviceNotVerified:
        if (toRouteName === RouteName.DeviceVerification) {
          return;
        }

        return resolveRoute(RouteName.DeviceVerification);

      default:
        return;
    }
  }

  return;
}
