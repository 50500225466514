import { computed } from 'vue';
import { useStore } from 'vuex';

export default function () {
  const { state, getters } = useStore();

  const user = computed(() => state.users.user);

  const username = computed<string>(() => getters['users/userNickName']);

  const userEmail = computed<string>(() => getters['users/userEmail']);

  const isOnboardingCompleted = computed<boolean>(
    () => getters['users/isOnboardingCompleted'],
  );

  return {
    user,
    username,
    userEmail,
    isOnboardingCompleted,
  };
}
