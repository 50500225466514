import { NavigationGuard } from 'vue-router';

import { RouteName } from '@/app/types/app/routes';

import Store from '@/store';

const deviceVerificationGuard: NavigationGuard = (to, from, next) => {
  const isDeviceVerified = Store.state.access.isDeviceVerified;

  if (isDeviceVerified) {
    next({ name: RouteName.Dashboard });
  }

  next();
};

export default deviceVerificationGuard;
