export enum RouteName {
  Dashboard = 'dashboard',
  ContactUs = 'contact-us',
  // transactions
  TransactionsHistory = 'transactions-history',
  TransactionsHistoryCrypto = 'transactions-history-crypto',
  TransactionsHistoryFiat = 'transactions-history-fiat',
  CryptoTransactionDetails = 'crypto-transaction-details',
  FiatTransactionDetails = 'fiat-transaction-details',
  // balances
  Balances = 'balances',
  Exchange = 'exchange',
  BalanceStatement = 'balance-statement',
  OrgAccounts = 'org-accounts',
  // withdraw
  Withdraw = 'withdraw',
  WithdrawCrypto = 'withdraw-crypto',
  WithdrawFiat = 'withdraw-fiat',
  // topup
  Topup = 'topup',
  TopupCrypto = 'topup-crypto',
  TopupFiat = 'topup-fiat',
  // documents
  Documents = 'documents',
  FundingInstructions = 'funding-instructions',
  VatInvoices = 'vat-invoices',
  MerchantReports = 'merchant-reports',
  DocumentsReports = 'documents-reports',
  // business
  BusinessPayments = 'business-payments',
  BusinessPaymentsInvoices = 'business-payments-invoices',
  BusinessPaymentsInvoiceDetails = 'business-payments-invoice-details',
  BusinessPaymentsDonations = 'business-payments-donations',
  MassPayouts = 'mass-payouts',
  MassPayoutsDetails = 'mass-payouts-details',
  // payment
  PaymentSingle = 'payment-single',
  // settings
  ProfileSettings = 'profile-settings',
  BankingSettings = 'banking-settings',
  // payouts settings
  PayoutsSettingsApi = 'payouts-settings-api',
  PayoutsSettingsAccount = 'payouts-settings-account',
  // merchant settings
  MerchantSettingsProfile = 'merchant-settings-profile',
  MerchantSettingsPaylink = 'merchant-settings-paylink',
  MerchantSettingsDonations = 'merchant-settings-donations',
  MerchantSettingsGatewayApi = 'merchant-settings-gateway-api',
  MerchantSettingsCurrencies = 'merchant-settings-currencies',
  MerchantSettingsAccount = 'merchant-settings-account',
  MerchantSettingsNotifications = 'merchant-settings-notifications',
  // webhooks
  GatewayWebhooks = 'gateway-webhooks',
  PayersWebhooks = 'payers-webhooks',
  // requests
  Requests = 'requests',
  RequestsFiatWithdraw = 'fiat-withdraw-requests',
  RequestsExchange = 'exchange-requests',
  RequestsCryptoWithdraw = 'crypto-withdraw-requests',
  // service
  DeviceVerification = 'device-verification',
  Signature = 'signature',
  AccessRestricted = 'access-restricted',
  Maintenance = 'maintenance',
  NotFound = 'not-found',
}
